<template>
  <div class="container-fluid">
		<Load :isLoading="isLoading" />
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h3>Cadastro de pessoa física</h3>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-12 mb-3 text-right">
						<span class="font-weight-bold text-dark-color mhand" @click="mostraDocumentos = !mostraDocumentos">{{ mostraDocumentos ? 'Visualizar Dados >>>' : 'Visualizar documentos >>>'}}</span>
					</div>
				</div>
				<funcionario-documentos v-if="mostraDocumentos" :funcionarioId="funcionario.id" />
				<form @submit.prevent="salvar" v-if="!mostraDocumentos">
					<div class="row">
						<div class="col-12 d-flex">
							<div>
								<label class="custom-file">
									<input type="file" style="display:none;" id="attachments" @change="setImageCrop($event)" name="anexararquivo" placeholder="Anexar arquivos" class="custom-file-input" aria-describedby="fileHelpId">
									<!--<span class="custom-file-control btn btn-info btn-fill btn-wd">Selecionar arquivos</span><br>-->
									<div v-if="attachments.length > 0" class="attachment-holder animated fadeIn" >
										<div>
											<img
												:src="attachments[0].preview"
												style="height:200px;"
												class="img-fluid border-item custom-file-control"
											/>
										</div>
									</div>
									<div v-else>
										<img
											src="@/assets/images/user-shadow.png"
											style="height:200px;"
											class="img-fluid border-item custom-file-control"
										/>
									</div>
									<small style="color: red;" class="font-weight-bold ml-3">Tam. máximo: 2mb</small>
								</label>
							</div>
							<div v-if="attachments.length > 0">
								<span class="col-md-4" style="cursor: pointer;" @click="removeAttachment()"><i class="fas fa-times text-danger mhand"></i></span>
							</div>
							<div class="row-cols-1" v-if="imgCrop !== null">
								<ImageCrop :src="imgCrop" @imagemFinal="ImagemFinal" @fechar="imgCrop = null" />
							</div>
							<!--
							<div v-else class="row-cols-1 small">
								<div class="row">
									<div class="col attachment-holder animated fadeIn" v-cloak v-for="(attachment, index) in attachments" :key="index">
										<div class="text-center">
											<img
												:src="attachment.preview"
												style="width:300px; height:400px;"
												class="img-fluid border-item custom-file-control"
											/>
											<div>
												<span class="col-md-4" style="cursor: pointer;" @click="removeAttachment(index)"><i class="fas fa-times text-danger mhand"></i></span>
											</div>
										</div>
									</div>
								</div>
							</div>
							-->
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.funcionario.nome.$error }">
								<label for="razaosocial" class="required">Nome</label>
								<input v-model="funcionario.nome" type="text" class="form-control" name="nome" id="nome"  placeholder="Nome">
								<div class="error-form text-danger" v-if="!$v.funcionario.nome.required">Campo obrigatório</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.cpf.$error }">
								<label for="cpf" class="required">CPF</label>
								<input v-model="cpf" type="text" class="form-control" name="cpf" id="cpf" placeholder="cpf" v-mask="'###.###.###-##'">
								<div class="error-form text-danger" v-if="!$v.cpf.required">Campo obrigatório</div>
								<div class="error-form text-danger" v-if="!$v.cpf.CpfValido && cpf != null && cpf.length === 14">Informe um cpf válido</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="rg">RG</label>
								<input v-model="funcionario.rg" type="text" class="form-control" name="rg" id="rg" placeholder="rg" v-mask="'###.###.###-##'">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="data_nascimento">Data de nascimento</label>
								<input v-model="funcionario.data_nascimento" type="text" class="form-control" v-mask="'##/##/####'" name="data_nascimento" id="data_nascimento"  placeholder="99/99/9999">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="estado_civil_id">Estado civil</label>
								<select class="form-control" v-model="funcionario.estado_civil_id">
									<option v-for="(item, index) of estadosCivis" :key="index" :value="item.id">{{item.nome}}</option>
								</select>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="sexo">Sexo</label>
								<select class="form-control" v-model="funcionario.sexo">
									<option value="F">Feminino</option>
									<option value="M">Masculino</option>
								</select>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="data_admissao" >Data de admissão</label>
								<input v-model="funcionario.data_admissao" v-mask="'##/##/####'" type="text" class="form-control" name="data_admissao" id="data_admissao">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="deficiencia_id">Deficiência</label>
								<select v-model="funcionario.deficiencia_id" class="form-control" name="deficiencia_id">
									<option value="" selected>NENHUMA</option>
									<option v-for="(item, index) of deficiencias" :key="index" :value="item.id">{{item.nome}}</option>
								</select>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="aso">ASO</label>
								<input v-model="funcionario.aso" type="text" class="form-control" name="aso" id="aso" placeholder="ASO" >
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<button type="button" class="btn btn-primary mt-5" @click="geraQrCode">Gerar QR Code</button>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-12 mt-5">
							<button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
							<button type="submit" class="btn btn-primary float-right">SALVAR</button>
						</div>
					</div>
				</form>
			</div>
		</div>
  </div>
</template>

<script>
import constants from './../../../constants'
import {OpenWindowWithPost} from './../../services/windowPost.service'
import { ValidarCpf } from './../../services/cpf.service'
import funcionariosRepository from './../../services/api/funcionariosRepository'
import deficienciasRepository from './../../services/api/deficienciasRepository'
import configuracoesRepository from './../../services/api/configuracoesRepository'
import midiaRepository from './../../services/api/midiaRepository'
import { required } from 'vuelidate/lib/validators'
import ImageCrop from './../../uicomponents/CropperImage'
const CpfValido = (value) => ValidarCpf(value)
import FuncionarioDocumentos from './documentos/FuncionarioDocumentos.vue'
export default {
	validations: {
    funcionario: {
			nome: {
				required
      }      
		},
		cpf: {
			required,
			CpfValido
		}
  },
	components: {
		ImageCrop,
		FuncionarioDocumentos
	},
	data () {
		return {
			mostraDocumentos: false,
			isLoading: false,
			imgCrop: null,
			attachments: [],
			funcionario: {
				id: 0,
        nome: null,
				foto: null,
			},
			cpf: '',
			estadosCivis: [],
			deficiencias: []
		}
	},
	async created () {
		await this.fetch()
		if (this.$route.query.id) {
			this.buscaFuncionario(this.$route.query.id)
		}
	},
	watch: {
    cpf: {
      handler () {
        if (this.cpf && this.cpf !== null && this.cpf.length == 14) {
					this.VerificaCpf()
				}
      }
    }
  },
	methods: {
		ImagemFinal (imagem) {
			const item = {
				arquivo: imagem,
				preview: URL.createObjectURL(imagem)
			}
			//this.attachments.push(item)
			this.attachments[0] = item
			this.imgCrop = null
		},
		setImageCrop (e) {
			// this.imgCrop = e.target.files[0]
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onload = (event) => {
				// this.imgSrc = event.target.result
				// rebuild cropperjs with the updated source
				this.imgCrop = event.target.result
				// this.imgCrop = event.target.files[0]
			}
			reader.readAsDataURL(file)
		},
		attachImagem (imagem) {
			const item = {
				arquivo: imagem,
				preview: URL.createObjectURL(imagem)
			}
			//this.attachments.push(item)
			this.attachments[0] = item
			this.imgCrop = null
		},
		async salvarMidias () {
			const formDataitems = new FormData()
			let upload = false
			for (let i = 0; i < this.attachments.length; i++) {
				if (this.attachments[i].arquivo != null) {
					upload = true
					const attachment = this.attachments[i].arquivo
					formDataitems.append(`midias[${i}]`, attachment, attachment.name)
				} else {
					const url = this.attachments[i].preview.replace(`${constants.API_URL}/storage/`, '')
					this.funcionario.foto = url
				}
			}
			if (upload) {
				try {
					const response = await midiaRepository.SalvarMidia(formDataitems)
					if (response.data.success) {
						console.log(response.data.data)
						this.funcionario.foto = response.data.data[0]
						/*
						response.data.data.map(obj => {
							this.funcionario.foto = obj
						})
						*/
					}
				} catch (error) {
					console.log(error)
				}
			}
		},
		attach: function (e) {
			var files = e.target.files || e.dataTransfer.files
			if (!files.length) {
				return
			}
			for (var i = files.length - 1; i >= 0; i--) {
				if (files[i].size / 1024 / 1024 <= 3096) {
					const item = {
						arquivo: files[i],
						preview: URL.createObjectURL(files[i])
					}
					//this.attachments.push(item)
					this.attachments[0] = item
					// this.attachments.push(files[i])
				} else {
					this.$swal({
						type: 'error',
						title: 'Erro de arquivo',
						text: 'Arquivo não anexado. Tamanho maior que 3mb.'
					})
				}
			}
			document.getElementById('attachments').value = []
		},
		removeAttachment () {
			this.funcionario.foto = null
			this.attachments = []
			this.imgCrop = null
		},
		geraQrCode() {
			let param = {link: this.$route.query.id}
			OpenWindowWithPost(`${constants.API_URL}/credencial/qrcode`, "", "Credencial", param);
		},
		VerificaCpf() {
			let cpf = this.cpf
			cpf = cpf.replace(/\./g,'').replace('-','')
			let check = ValidarCpf(cpf)
			if (!check) {
				this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Cpf inválido!',
				})
			}
			return check
		},
		fetch () {
			configuracoesRepository.buscaEstadoCivil().then(response => {
				if (response.data['success']) {
					this.estadosCivis = response.data['data']
				}
			})
			deficienciasRepository.listarDeficiencias().then(responseDeficiencia => {
				if (responseDeficiencia.data['success']) {
					this.deficiencias = responseDeficiencia.data['data']
				}
			})
		},
		buscaFuncionario (idFuncionario) {
			this.isLoading = true
			funcionariosRepository.buscaFuncionario(idFuncionario).then(response => {
				this.isLoading = false
				let funcionario = Object.assign({}, response.data['data'])
				if(funcionario.data_nascimento) {
					funcionario.data_nascimento = funcionario.data_nascimento.split('-').reverse().join('/')
				}
				if(funcionario.data_admissao) {
					funcionario.data_admissao = funcionario.data_admissao.split('-').reverse().join('/')
				}
				this.funcionario = funcionario
				this.cpf = funcionario.cpf
				console.log(`${constants.API_URL}/storage/${this.funcionario.foto}`)
				if(this.funcionario.foto !== null) {
					let linkPreview =  `${constants.API_URL}/storage/${this.funcionario.foto}`
					this.attachments[0] = { arquivo: null, preview: linkPreview }
				}
			})
    },
		goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'Funcionarios' })
		},
		async verificarCpf(cpf) {
			console.log(cpf)
			try {
				let response = await funcionariosRepository.verificarCpf(cpf)
				if(response.data['data']) {
					return response.data['data']
				} else {
					return null
				}
			}catch(e) {
				console.log(e)
				return null
			}
		},
		async salvar () {
			this.$v.$touch()
			if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha o formulário corretamente!',
				})
      } else {
				if (this.attachments.length > 0) {
					this.isLoading = true
					await this.salvarMidias()
					this.isLoading = false
				}
				let funcionario = Object.assign({}, this.funcionario)
				funcionario.cpf = this.cpf
				if(funcionario.data_nascimento) {
					funcionario.data_nascimento = funcionario.data_nascimento.split('/').reverse().join('-')
				}
				if(funcionario.data_admissao) {
					funcionario.data_admissao = funcionario.data_admissao.split('/').reverse().join('-')
				}
				if (funcionario.id) {
					this.isLoading = true
					funcionariosRepository.atualizarFuncionario(funcionario.id, funcionario).then(response => {
						this.isLoading = false
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch( error => {
						this.isLoading = false
						console.log(error)
						this.$swal({
							icon: 'error',
							text: 'Não foi possível realizar o cadastro!'
						})
					})
				} else {
					let existeCpf = await this.verificarCpf(funcionario.cpf)
					if(existeCpf) {
						this.$swal({
							icon: 'warning',
							text: `CPF já cadastrado em nome de ${existeCpf.nome}!`
						})
					} else {
						this.isLoading = true
						funcionariosRepository.salvarFuncionario(funcionario).then(response => {
							this.isLoading = false
							if (response.data['success']) {
								this.$swal({
									icon: 'success',
									text: 'Funcionário cadastrado com sucesso!'
								}).then( () => {
									// this.goBack()
									this.$router.go()
								})
							} else {
								this.$swal({
									icon: 'error',
									text: 'Não foi possível realizar o cadastro!'
								})
							}
						}).catch( error => {
							this.isLoading = false
							console.log(error)
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o cadastro!'
							})
						})
					}
				}
			}
		}
	}
}
</script>

<style>

</style>