<template>
  <transition name="fade" v-if="src != null && src !== ''">
    <div class="align-items-center animated d-flex modal-mask">
      <div class="modal-dialog text-center">
        <div class="modal-content align-items-center" style="background-color: transparent;">
          <div class="crop-container row">
						<div class="col-12">
							<img :src="src" ref="imageInput" alt="" crossorigin style="max-height: 400px;">
						</div>
						<div class="col-12">
							<button type="button" @click="retornarRecorte()" class="btn btn-primary mhand mx-2 my-3">
								Recortar
							</button>
							<button type="button" @click="$emit('fechar')" class="btn btn-danger mhand my-3 mx-2">
								Cancelar
							</button>
						</div>
					</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Cropper from 'cropperjs'
export default {
	props: {
		src: {
			type: String,
			default: ''
		}
	},
	data () {
		return {
			destination: {}
		}
	},
	mounted () {
		this.imageInput = this.$refs.imageInput
		this.cropper = new Cropper(this.imageInput, {
			aspectRatio: 3 / 4,
			zoomable: false,
			scalable: false,
			crop: () => {
				const canvas = this.cropper.getCroppedCanvas()
				this.destination = canvas.toDataURL('image/png')
			}
		})
	},
	methods: {
		retornarRecorte () {
			const imagem = this.dataURLtoFile(this.destination, this.makeid(5) + '.png')
			this.$emit('imagemFinal', imagem)
			this.destination = {}
		},
		dataURLtoFile (dataurl, filename) {
			var arr = dataurl.split(',')
			var mime = arr[0].match(/:(.*?);/)[1]
			var bstr = atob(arr[1])
			var n = bstr.length
			var u8arr = new Uint8Array(n)
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n)
			}
			return new File([u8arr], filename, { type: mime })
		},
		makeid (length) {
			var result = ''
			var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			var charactersLength = characters.length
			for (var i = 0; i < length; i++) {
				result += characters.charAt(
					Math.floor(Math.random() * charactersLength)
				)
			}
			return result
		}
	}
}
</script>

<style>
  .crop-container {
    width: 400px;
    height: 400px;
  }
  .cropper-container {
    height: 400px !important;
  }
  @import url('https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.9/cropper.min.css')

</style>
