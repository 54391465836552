import Repository from './repository'

const resource = '/v1/midias'

export default {
	ListarProdutoMidiaPorId (id) {
		return Repository.get(`${resource}/${id}`)
	},
	ListarProdutosMidias () {
		return Repository.get(`${resource}`)
	},
	SalvarMidia (data) {
		return Repository.post(`${resource}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
	},
	AtualizarProdutoMidia (id, data) {
		return Repository.put(`${resource}/${id}`, data)
	},
	DeletarProdutoMidia (id) {
		return Repository.delete(`${resource}/${id}`)
	}
}
