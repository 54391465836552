<template>
  <transition name="fade">
    <div class="modal-mask animated">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Upload de documentos</h4>
            <button type="button" class="close" data-dismiss="modal" @click="$emit('fechar')">&times;</button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col text-right">
                <button type="button" class="btn btn-outline-info p-1" style="font-size: small;" @click="addDocumento()">Adicionar documento</button>
              </div>
            </div>
            <div class="row" v-for="(item, index) in documentos" :key="index">
              <div class="col-12 col-md-5 pr-0">
                <div class="form-group">
                  <input type="text" v-model="item.nomeDocumento" class="form-control" placeholder="Nome do documento">
                </div>
              </div>
              <div class="col-12 col-md-7">
                <label class="align-items-center custom-file d-flex">
                  <span v-if="!item.file">
                    <input type="file"  style="display:none;" id="attachments" @change="attach($event, index)" name="anexararquivo" placeholder="Anexar arquivos" class="custom-file-input">
                    <span class="custom-file-control btn btn-sm btn-info btn-fill btn-wd"  >Anexar arquivo</span>
                  </span>
                  <span v-if="item.file">
                    <span style="cursor: pointer;" @click="removeAttachment(index)"><a class="btn btn-danger btn-sm text-white">Remover</a></span>
                    <span class="label label-primary text-small">{{ ' (' + Number((item.file.size / 1024).toFixed(1)) + 'Kb)'}}</span>
                  </span>
                  <!-- <small style="color: red;" class="font-weight-bold ml-3">Tam. máximo: 1mb</small> -->
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-around modal-footer">
            <button v-if="documentos.length > 0" type="submit" class="btn btn-sm btn-primary float-right">ENVIAR ARQUIVOS</button>
          </div>
         
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      documentos: []
    }
  },
  methods: {
    addDocumento () {
      let item = {
        nomeDocumento: '',
        file: null
      }
      this.documentos.push(item)
    },
    attach: function (e, index) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      for (var i = files.length - 1; i >= 0; i--) {
        this.documentos[index].file = files[i]
        // if (files[i].size / 1024 / 1024 <= 1024) {
        //   this.documentos.file = files[i]
        // } else {
        //   this.$swal({
        //     type: 'error',
        //     title: 'Erro de arquivo',
        //     text: 'Arquivo não anexado. Tamanho maior que 1mb.'
        //   })
        // }
      }
      document.getElementById('attachments').value = []
    },
    removeAttachment (attachment) {
      this.documentos.splice(attachment, 1)
    }
  }
}
</script>

<style>

</style>