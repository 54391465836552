<template>
  <div class="row">
    <div class="col-12">
      <button type="submit" class="btn btn-primary float-right" @click="upload = true">Cadastrar documentos</button>
    </div>
    <funcionario-documentos-upload v-if="upload" @fechar="fecharModal" />
    <div class="col-12">
      <MyTable
        :searchable="true"
        :data="documentos"
        :columns="columns"
        :itemsPerPage="10">
        <template v-slot:row="{ row }">
          <td>
            {{row.nome_arquivo}}
          </td>
          <td>
            Visualizar arquivo
          </td>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import empresaDocumentosRepository from './../../../services/api/empresaDocumentosRepository'
import MyTable from './../../../uicomponents/MyTable'
import FuncionarioDocumentosUpload from './FuncionarioDocumentosUpload.vue'
export default {
  components: {
    MyTable,
    FuncionarioDocumentosUpload
  },
  props: {
    empresaId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      upload: false,
      documentos: [],
      columns: [
        { label: 'Nome arquivo', field: 'nome_arquivo', sortable: 'nome_arquivo' },
        { label: 'Data inclusão', field: 'created_at', sortable: 'created_at' },
        { label: 'Visualizar', field: '', sortable: '' },
      ]
    }
  },
  mounted () {
    this.$store.commit("setSplashScreen", false)
    if(this.empresaId > 0) {
      this.listar(this.empresaId)
    }
  },
  methods: {
    listar(idEmpresa) {
      this.$store.commit("setSplashScreen", true)
      empresaDocumentosRepository.listarDocumentosPorEmpresaId(idEmpresa).then(response => {
        this.$store.commit("setSplashScreen", false)
        if(response.data.success) {
          this.documentos = response.data.data
        } else { 
          this.$swal({
            icon: "error",
            text: 'Ocorreu um erro ao listar os documentos!',
          })
        }
      }).catch(error => {
        this.$store.commit("setSplashScreen", false)
        console.log(error.response)
        if(error.response.status != 500) {
          this.$swal({
            icon: "error",
            text: error.response.data.errors[0],
          })
        }        
      })
    },
    fecharModal(refresh = false) {
      this.upload = false
      if(refresh) {
        this.listar(this.empresaId)
      }
    }
  }
}
</script>

<style>

</style>